import React, { useEffect, useState } from "react"
import { FahrtenAtlas } from "../components/fahrtenatlas/styled"
import Layout from "../components/Layout"
import Map from "../components/fahrtenatlas/Map"
import { graphql } from "gatsby"

export default function FahrtenatlasPage({ data }) {
  const [paths, setPaths] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // nodes contains all paths
    const { edges: nodes } = data.allMdx

    // the reducer pulls the arrays out of the reports
    const reducer = (accumulator, currentValue) => {
      accumulator.push(currentValue.node.frontmatter.path)
      return accumulator
    }

    setPaths(nodes.reduce(reducer, []))
    setLoading(false)
  }, [data])

  if (!loading)
    return (
      <Layout>
        <FahrtenAtlas>
          {/*<link*/}
          {/*  rel="stylesheet"*/}
          {/*  href="https://unpkg.com/leaflet@1.0.1/dist/leaflet.css"*/}
          {/*/>*/}
          <Map paths={paths} center={[49.7465, 8.115183]} height={500} />
        </FahrtenAtlas>
      </Layout>
    )
  else return <p>"loading"</p>
}

// noinspection JSUnusedGlobalSymbols
export const pageQuery = graphql`
  query {
    allMdx {
      edges {
        node {
          frontmatter {
            path
          }
        }
      }
    }
  }
`
