import styled from "styled-components"
import breakpoints from "../../constants/breakpoints"

export const FahrtenAtlas = styled.div`
  display: flex;
  justify-content: center;

  .map {
    height: 300px;
    width: 90vw;

    @media (min-width: ${breakpoints.large}px) {
      height: 400px;
      width: 1024px;
    }
  }
`
